





















































































































































































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
// import scheduleModule from '@/store/modules/scheduleModule';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends Vue {
  @Prop({ type: Object, required: false }) tasq?: TasqJob;

  selectedActions: any = []

  selectedPad: any = null;

  dataLoading = true;

  actionErrors: any = []

  selectedWell: any = null;

  windowWidth = window.innerWidth;

  selectedActionCategory = '';

  showComment = false;

  comment = '';

  selectedAssetType = 'Well';

  selectedPerson = { value: accountModule.user.email, text: `${accountModule.user.firstName} ${accountModule.user.lastName}` };

  stepNo = 0;

  similarTasqs: any = [];

  categoryOptions: any = []

  actionItems: any = []

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  async created() {
    this.dataLoading = true;
    const promiseList: any = [];
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(assetsModule.getEnabledPads());

    Promise.all(promiseList).then(async () => {
      if (this.tasq && this.tasq.wellName) {
        this.selectedWell = { text: this.tasq.wellName, value: this.tasq.wellName };
      }
      // console.log('resolved');
      await this.fetchActions();
      this.dataLoading = false;
    }, (err) => {
      console.log('Error:');
      console.log(err);
    });
  }

  get actionOptions() {
    if (this.actionItems.find((action) => action.title === this.selectedActionCategory)) {
      console.log(this.actionItems.find((action) => action.title === this.selectedActionCategory).actions);
      return this.actionItems.find((action) => action.title === this.selectedActionCategory).actions;
    }
    return [];
  }

  async fetchActions() {
    let forms: any = metaDataModule.formBuilderData;

    //  console.log(forms);

    forms = forms.map((form) => ({
      validateForToday: false,
      isCompleteForToday: false,
      ...form,
    }));
    forms = forms.reduce((r, a) => {
      if (!a.category) {
        a.category = 'None';
      }
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));
    this.actionItems = Object.keys(forms).map((key) => ({ title: key, actions: forms[key] }));

    console.log(this.actionItems);

    this.categoryOptions = (this.actionItems).map((action) => action.title);
    //  tasqFeedbackModule.setActionBuilderActions(actionBuilderActions)
    // console.log(this.actionBuilderActions);
    // this.dataLoading = false;
  }

  get isMobile() {
    return this.windowWidth < 769;
  }

  validateForm() {
    if (this.selectedAssetType === 'Well') {
      if (!this.selectedWell) {
        this.actionErrors.push('selectedWell');
      }
    }

    if (this.selectedAssetType === 'Pad') {
      if (!this.selectedPad) {
        this.actionErrors.push('selectedPad');
      }
    }

    if (!this.selectedActionCategory) {
      this.actionErrors.push('selectedCategory');
    }

    if (!this.selectedActions.length) {
      this.actionErrors.push('selectedAction');
    }
  }

  assignPerson() {
    this.actionErrors = [];

    this.validateForm();

    if (this.actionErrors.length) {
      return;
    }

    this.stepNo = 1;
  }

  async createNewTasq() {
    this.dataLoading = true;

    this.actionErrors = [];

    this.validateForm();

    if (this.actionErrors.length) {
      this.dataLoading = false;
      return;
    }
    const actions = this.selectedActions;
    let well = this.selectedWell.value;
    if (this.selectedAssetType === 'Pad') {
      well = this.selectedPad.value;
    }
    const predictionID = await tasqActionsModule.addTasq({
      // eslint-disable-next-line max-len
      assetType: this.selectedAssetType, person: this.selectedPerson, well, comment: this.comment, actions, jobType: this.selectedActionCategory,
    });

    await tasqsListModule.getRawTasq({ PredictionID: predictionID });
    //  await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });

    await sleep(1500);
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: predictionID || '',
      },
      query: { type: 'id', tab: 'Feedback' },
    });

    if (this.isMobile) {
      tasqsListModule.setShowTasqDetailsMobile(true);
    }

    await sleep(1500);
    this.dataLoading = false;
    this.$emit('close-manual-tasq-popup');
  }
}

